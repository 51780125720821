import React from "react"
import { useGlobalContext } from "../../context/global_context"

const Dropdown = ({ name, title, selection, index }) => {
  const { umbrella, handleChange } = useGlobalContext()

  return (
    <div key={index} className="dwelling-container">
      <label htmlFor={name} className="label">
        <h3>{title}:</h3>
      </label>
      <select
        name={name}
        value={umbrella[name]}
        onChange={handleChange}
        // required
      >
        {selection.map((value, index) => {
          return (
            <option key={index} value={value}>
              {value}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default Dropdown
