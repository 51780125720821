import React from "react"
import { useGlobalContext } from "../../context/global_context"

const PoliciesExtended = () => {
  const { umbrella } = useGlobalContext()
  const { policiesExtended } = umbrella
  console.log(policiesExtended)
  return (
    <>
      <h3>Policies Extended To:</h3>
      <p>
        These are the policies that your personal umbrella policy extends to:
      </p>
      <ul className="list">
        {policiesExtended.map((item, index) => {
          return (
            <li key={index} className="checkbox">
              {item}
            </li>
          )
        })}
      </ul>
      <br />
      <p>
        Your limits must be exhausted on your policies listed above before the
        umbrella policy will begin to payout.
      </p>
      <br />
      <p>
        If we are missing a policy or possible exposure, such as an investment
        property or motorcycle please let us know.
      </p>
      <br />
      <p>
        Also, please note that if you have policies with another carrier, this
        umbrella policy will not extend to those policies.
      </p>
      <br />
    </>
  )
}

export default PoliciesExtended
