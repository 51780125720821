import React from "react"
import { useGlobalContext } from "../../context/global_context"
import Checkbox from "../global/Checkbox"
import Dropdown from "../global/Dropdown"

const HomeCoverage = () => {
  const { data } = useGlobalContext()

  return (
    <div className="row">
      {/* home coverage input */}
      {data.map((item, index) => {
        const { name, title, selection, type } = item

        return (
          <div key={index} className="">
            {type === "dropdown" ? (
              <Dropdown
                name={name}
                title={title}
                index={index}
                selection={selection}
              />
            ) : (
              <Checkbox
                name={name}
                title={title}
                index={index}
                type={type}
                selection={selection}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default HomeCoverage
