import React from "react"
import ExcessLiability from "./ExcessLiability"
import PersonalInjury from "./PersonalInjury"
import PoliciesExtended from "./PoliciesExtended"

// Document Component
const Document = () => {
  return (
    <>
      <div>
        <p>Dear [firstname],</p>
        <br />
        <p>
          Your umbrella insurance policy is about to renew and this presents us
          with a good time to discuss any concerns or questions that you may
          have about your policy.
        </p>
        <br />
        <p>
          To ensure that you are aware of your coverages, we have put together
          the following review of your policy. If you would like to discuss any
          part of your policy, please give us a call at [agencyphone] or simply
          reply to this email.
        </p>
        <br />
        <h3>Coverage Explanations</h3>
        <br />

        {/* Dynamic Data */}
        <PoliciesExtended />
        <ExcessLiability />
        <PersonalInjury />

        <p>
          If you have any questions about the coverages listed above, please do
          not hesitate to reach out to us either by replying to this email or
          calling at [agencyphone].
        </p>
        <br />
        <p>
          Thank you for being a member of our agency, we recognize that there
          are plenty of other options out there. We strive to give you the best
          service we can. If there is anything, we can help you with, please let
          us know.
        </p>
        <br />
        <p>Best Regards,</p>
        <br />
        <br />
        <br />
        <p>[signature]</p>
      </div>
    </>
  )
}

export default Document
