import React from "react"
import { useGlobalContext } from "../../context/global_context"

const Checkbox = ({ name, title, type, selection, index }) => {
  const { umbrella, handleCheckboxChange } = useGlobalContext()

  return (
    <div key={index} className="checkbox-container">
      <div className="label">
        <h3>{title}:</h3>
      </div>
      <ul className="checkbox-list">
        {selection.map((item, index) => {
          return (
            <li key={index} className="checkbox">
              <div className="">
                <input
                  name={item}
                  type={type}
                  value={name}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor={item}>{item}</label>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Checkbox
