import React from "react"
import HomeCoverage from "./HomeCoverage"
import { useGlobalContext } from "../../context/global_context"

const FormOne = () => {
  const { handleNextCoverageStep } = useGlobalContext()

  return (
    <form className="form" onSubmit={handleNextCoverageStep}>
      <div className="form-center">
        <div className="row">
          <h2>Umbrella</h2>
          <HomeCoverage />
        </div>
        <hr className="line" />
        <div className="btn-container">
          {/* next button */}
          <button className="btn next-btn" type="submit">
            Next
          </button>
        </div>
      </div>
    </form>
  )
}

export default FormOne
