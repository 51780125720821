import React from "react"
import { useGlobalContext } from "../../context/global_context"

const ExcessLiability = () => {
  const { data } = useGlobalContext()
  const { excessLiability } = data

  return (
    <>
      <h3>Excess Liability:</h3>
      <p>
        {excessLiability === "$2 Million"
          ? "You currently carry two million dollars of excess liability coverage for the policies listed above. You may be eligible to increase your liability coverage. Please reach out to us by either replying to this email or giving us a call."
          : excessLiability === "$3 Million"
          ? "You currently carry three million dollars of excess liability coverage for the policies listed above. You may be eligible to increase your liability coverage. Please reach out to us by either replying to this email or giving us a call."
          : excessLiability === "$4 Million"
          ? "You currently carry four million dollars of excess liability coverage for the policies listed above. You may be eligible to increase your liability coverage. Please reach out to us by either replying to this email or giving us a call."
          : excessLiability === "$5 Million"
          ? "You currently carry five million dollars of excess liability coverage for the policies listed above. If you require more than five million dollars in excess coverage, please reach out to us and we can review your options."
          : "You currently carry one million dollars of excess liability coverage for the policies listed above. You may be eligible to increase your liability coverage. Please reach out to us by either replying to this email or giving us a call."}
      </p>
      <br />
    </>
  )
}

export default ExcessLiability
