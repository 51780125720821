import React from "react"
import { useGlobalContext } from "../../context/global_context"

const PersonalInjury = () => {
  const { data } = useGlobalContext()
  const { personalInjury } = data

  return (
    <>
      <h3>Personal Injury:</h3>
      <p>
        {personalInjury === "$500,000"
          ? "Personal Injury protects you in the case you are found liable for damages for slander, defamation of character, or libel. Your current limits are set to $500,000. You can increase this to one million dollars if you would like."
          : personalInjury === "$1 Million"
          ? "Personal Injury protects you in the case you are found liable for damages for slander, defamation of character, or libel. Your current limits are set to one million dollars."
          : "Personal Injury protects you in the case you are found liable for damages for slander, defamation of character, or libel. Your current limits are set to $300,000. You can increase this to either $500,000 or one million dollars if you would like."}
      </p>
      <br />
    </>
  )
}

export default PersonalInjury
